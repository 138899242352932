const getUrlsByCountry = country => {
  let urls = {
    production: "",
    examsSearch: "",
    specialty: "",
    backoffice: "",
  };

  switch (country) {
    case "BR":
      urls.production = process.env.GATSBY_GENEONE_URL;
      urls.backoffice = "/geneone";
      urls.examsSearch = "/api/search-exam";
      urls.specialty = "/api/specialty-areas";
      break;
      
    case "AR":
      urls.production = process.env.GATSBY_GENEONE_URL_AR;
      urls.backoffice = "/geneone-ar";
      urls.examsSearch = "/api/search-exam-ar";
      urls.specialty = "/api/specialty-areas-ar";
      break;

    default:
      urls.production = process.env.GATSBY_GENEONE_URL;
      urls.backoffice = "/geneone";
      urls.examsSearch = "/api/search-exam";
      urls.specialty = "/api/specialty-areas";
      break;
  }

  return urls;
};

module.exports = getUrlsByCountry;
