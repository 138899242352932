const axios = require('axios')
const getUrlsByCountry = require('./getUrlsByCountry')
const routes = getUrlsByCountry(process.env.GATSBY_COUNTRY)

const getExamsApi = async (term, page, specialtyIds, ans, handleApiError) => {
  const termParam = term !== 'initial_param' ? term : false
  const ansParam = ans !== 'initial_param' ? ans : ''
  const specialtyParam = specialtyIds.join(',')
  const routeApiExams = routes.examsSearch
  const routeName = 'exams'

  const urlParams = 
    `${termParam  ? `?q=${term}` : '?q='}` +
    `${specialtyParam ? `&area=${specialtyIds}` : ''}` +
    `${ansParam !== '' ? `&ans=${ans}` : ''}` +
    `${`&page=${page}`}`

  return sendData(routeApiExams, urlParams, 'GET', false, handleApiError, routeName);
}

const getSpecialtyApi = async (handleApiError) => {
  const urlParams = false
  const routeName = 'specialty'
  return sendData(routes.specialty, urlParams, 'GET', false, handleApiError, routeName)
}

const sendData = async (uri, urlParams, method, data = false, handleApiError, routeName) => {
  if (typeof uri !== 'undefined') {
    try {
      const url = `${process.env.GATSBY_LARAVEL_API_URL}${uri}${urlParams ? urlParams : ''}`
      let request = {
        method,
        url,
      }
  
      if (data) {
        request = { ...request, data }
      }
  
      const fetchedResponse = await axios(request)
      handleApiError(routeName, false);
  
      return fetchedResponse.data
    } catch (error) {
      console.error('Erro na requisição:', error.message);
      handleApiError(routeName, true);
  
      throw error;
    }
  }
}

module.exports = {
  getExamsApi,
  getSpecialtyApi
}
